import '../assets/scss/footer.scss'

import logo_fb from '../assets/images/logo_fb.png'
import logo_youtube from '../assets/images/logo_youtube.png'
import logo_zalo from '../assets/images/zalo.png'
import logo_cod from '../assets/icons/cod-payment.svg'
import logo_vnpay from '../assets/images/logo_vnpay.png'
// import logo_dhl from '../assets/images/logo_dhl.png'
// import img_qr from '../assets/images/img_qr.png'
// import logo_google_store from '../assets/images/logo_google_store.png'
// import logo_apple_store from '../assets/images/logo_apple_store.png'
import logo_omi_footer from '../assets/images/logo_omi_footer.png'
import logo_omi_footer_ja from '../assets/images/logo_omi_footer_ja.png'
import ic_location from '../assets/icons/ic_location.svg'
import ic_phone from '../assets/icons/ic_phone.svg'
import ic_email from '../assets/icons/ic_email.svg'
import logo_bct from '../assets/images/logo_bct.png'
import logo_dmca from '../assets/images/logo_dmca.png'
import { FB_LINK, LanguageList, YOUTUBE_LINK, YOUTUBE_LINK_PAGE, ZALO_LINK, languageJA } from '../constant'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTER } from '../router/config'
import { useGetStoreListQuery } from '../app/features/common'
import { selectLanguage } from '../app/slice'
import { useAppSelector } from '../app/hooks'

export function Footer() {
  const { t } = useTranslation()
  const { data: listStore } = useGetStoreListQuery({})
  const language = useAppSelector(selectLanguage)
  const languageList = LanguageList
  const langItem = languageList.find((item) => item.id === language) || languageList[0]
  return (
    <div className='footer' id={'footer'}>
      <div className='wrap-container footer-content'>
        <div className='row no-margin-hoz footer-content__list-link'>
          <div className='col-sm-3'>
            <h4 className='footer-list-title footer-list-title--bold'>{t('footer.customerSupport')}</h4>
            <ul className='footer-list'>
              <li className='footer-list__link'>
                <Link to={ROUTER.DELIVERY_POLICY_SCREEN}>{t('footer.deliveryPolicy')}</Link>
              </li>
              <li className='footer-list__link'>
                <Link to={ROUTER.RETURN_POLICY_SCREEN}>{t('footer.exchangePolicy')}</Link>
              </li>
              {/* <li className='footer-list__link'>
                <Link to={ROUTER.OMI_POINT_SCREEN}>{t('footer.omiPointPolicy')}</Link>
              </li> */}
              <li className='footer-list__link'>
                <Link to={ROUTER.ORDERING_GUIDE_SCREEN}>{t('footer.orderGuild')}</Link>
              </li>
            </ul>
          </div>
          <div className='col-sm-2'>
            <h4 className='footer-list-title footer-list-title--bold'>{t('footer.omiPharma')}</h4>
            <ul className='footer-list'>
              <li className='footer-list__link'>
                <Link to={ROUTER.ABOUT_OMI}>{t('footer.abouOmiPharma')}</Link>
              </li>
              <li className='footer-list__link'>
                <Link to={ROUTER.PRIVACY_POLICY_SCREEN}>{t('footer.privacyPolicy')}</Link>
              </li>
              <li className='footer-list__link'>
                <Link to={ROUTER.TERMS_OF_SERVICE_SCREEN}>{t('footer.serviceTerm')}</Link>
              </li>
              <li className='footer-list__link'>
                <Link to={ROUTER.NEWS_SCREEN}>{t('footer.companyNews')}</Link>
              </li>
            </ul>
          </div>
          <div className='col-sm-3'>
            <h4 className='footer-list-title footer-list-title--bold'>{t('footer.paymentMethod')}</h4>
            <div className='footer-logo'>
              <img src={logo_cod} alt={'logo'} />
              <img src={logo_vnpay} alt={'logo'} />
            </div>
            <h4 className='footer-list-title footer-list-title--normal'>{t('footer.connectUs')}</h4>
            <div className='footer-logo'>
              <a href={FB_LINK} target='_blank' rel='noreferrer'>
                <img src={logo_fb} alt={'logo'} />
              </a>
              <a href={YOUTUBE_LINK_PAGE} target='_blank' rel='noreferrer'>
                <img src={logo_youtube} alt={'logo'} />
              </a>
              <a href={ZALO_LINK} target='_blank' rel='noreferrer'>
                <img src={logo_zalo} alt={'logo'} width={40} />
              </a>
            </div>
          </div>
          <div className='col-sm-4' style={{ display: 'none' }}>
            <h4 className='footer-list-title footer-list-title--bold'>{t('footer.app')}</h4>
            <div className='footer-video'>
              {/* <img src={img_qr} alt={'logo'} /> */}
              <div className='video-wrap'>
                <iframe
                  width='240'
                  height='145'
                  loading='lazy'
                  src={YOUTUBE_LINK}
                  title={t('footer.youtubeTitle')}
                ></iframe>
              </div>
            </div>
            {/* <div className='footer-logo footer-logo--responsive'>
              <img src={logo_google_store} alt={'logo'} />
              <img src={logo_apple_store} alt={'logo'} />
            </div> */}
          </div>
        </div>
        <div className='footer-content__info'>
          <div className='row no-margin-hoz content-info'>
            <div className='col-sm-3 logo-wrap'>
              <img src={langItem.id === languageJA ? logo_omi_footer_ja : logo_omi_footer} alt={'logo-omi'} />
            </div>
            <div className='col-sm-6'>
              {listStore?.map((item, idx) => {
                return (
                  <div className='footer-item-info' key={item.id}>
                    <div className='icon-info'>
                      <img src={ic_location} alt={'icon-info'} />
                    </div>
                    <p>
                      {t('footer.branch')} {idx + 1}:<span>{item.address}</span>
                    </p>
                  </div>
                )
              })}
            </div>
            <div className='col-sm-3'>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_phone} alt={'icon-info'} />
                </div>
                <p>
                  {t('footer.phone')}
                  <span>{t('footer.PhoneNumber')}</span>
                </p>
              </div>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_email} alt={'icon-info'} />
                </div>
                <p>
                  {t('footer.mail')}
                  <span>{t('footer.customerMail')}</span>
                </p>
              </div>
            </div>
          </div>
          <div className='row no-margin-hoz content-copyright mt-3'>
            <div className='col-xl-8 col-lg-7 content-copyright__text'>
              <h4>{t('footer.companyName')}</h4>
              <p>{t('footer.businessRegistration')}</p>
              <p>{t('footer.address')}</p>
            </div>
            <div className='col-xl-4 col-lg-5 content-copyright__logo'>
              <div className='row col-12'>
                <div className='col-4'>
                  <p>{t('footer.omipharmaLink')}</p>
                </div>
                <div className='col-4'>
                  <p>{t('footer.omiCareApp')}</p>
                </div>
              </div>
              <div className='row col-12'>
                <div className='col-4 content-copyright__logo__item'>
                  <img src={logo_bct} alt='icon-copyright' />
                </div>
                <div className='col-4 content-copyright__logo__item'>
                  <img src={logo_bct} alt='icon-copyright' />
                </div>
                <div className='col-4 content-copyright__logo__item'>
                  <img src={logo_dmca} alt='icon-copyright' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
